import React from 'react';
import compact from 'lodash/compact';

import {
  FetchTeamFetched,
  FetchTeamIsPlaceholderData,
  TeamOwnerID,
  TeamNanoID
} from '../../../teamsTypes';
import { IconsEnum } from '../../../../../assets/icons/types';

import { useThirdPersonView } from '../../../../../app/hooks/useThirdPersonView';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';
import { MenuPlainLink } from '../../../../common/components/menus/MenuPlainLink';
import { MenuInviteNewUsersToCompanyModalButton } from '../../../../common/components/menus/MenuInviteNewUsersToCompanyModalButton';

import {
  TeamShowPageHeaderFinTeam,
  TeamShowPageHeaderTeam
} from '../../headers/TeamShowPageHeader';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';

import { TeamPath } from '../../../TeamPath';

import { TeamTabs, TeamsPermissions } from '../../../teamsConstants';
import { teamsKeys } from '../../../../../locales/keys';
import { TeamsUserCache } from '../../../../../main/teamsUsers/TeamsUserCache';
import { TeamInvitationCache } from '../../../../../main/teamInvitations/TeamInvitationCache';

interface TeamProfileSecondaryMenuProps {
  isSelf: boolean;
  isWorker: boolean;
  finTeam?: TeamShowPageHeaderFinTeam;
  team: {
    ownerId: TeamOwnerID;
    nanoId: TeamNanoID;
  } & TeamShowPageHeaderTeam;
  teamFetched: FetchTeamFetched;
  teamIsPlaceholderData: FetchTeamIsPlaceholderData;
  active: TeamTabs;
}

function TeamProfileSecondaryMenu({
  isSelf,
  isWorker,
  finTeam,
  team,
  teamFetched,
  teamIsPlaceholderData,
  active
}: TeamProfileSecondaryMenuProps) {
  const currentUser = useCurrentUser();
  const { companyNanoId: companyViewCompanyNanoId } = useThirdPersonView();

  const dashboardHref = companyViewCompanyNanoId
    ? TeamPath.companyDashboard(companyViewCompanyNanoId)
    : TeamPath.currentCompanyDashboard();

  const profileHref = companyViewCompanyNanoId
    ? TeamPath.companyProfile(companyViewCompanyNanoId)
    : TeamPath.currentCompanyProfile();

  const usersHref = companyViewCompanyNanoId
    ? TeamPath.companyUsers(companyViewCompanyNanoId)
    : TeamPath.currentCompanyUsers();

  const performersHref = companyViewCompanyNanoId
    ? TeamPath.companyPerformers(companyViewCompanyNanoId)
    : TeamPath.currentCompanyPerformers();

  const itemTypesHref = companyViewCompanyNanoId
    ? TeamPath.companyItemTypes(companyViewCompanyNanoId)
    : TeamPath.currentCompanyItemTypes();

  const incomingRevenueShareHref = companyViewCompanyNanoId
    ? TeamPath.companyIncomingRevenueShare(companyViewCompanyNanoId)
    : TeamPath.currentCompanyIncomingRevenueShare();

  const filesShareHref = companyViewCompanyNanoId
    ? TeamPath.companyAttachments(companyViewCompanyNanoId)
    : TeamPath.currentCompanyAttachments();

  const recordsHref = companyViewCompanyNanoId
    ? TeamPath.companyRecords(companyViewCompanyNanoId)
    : TeamPath.currentCompanyRecords();

  const reportsHref = companyViewCompanyNanoId
    ? TeamPath.companyReports(companyViewCompanyNanoId)
    : TeamPath.currentCompanyReports();

  const subbooksHref = companyViewCompanyNanoId
    ? TeamPath.companySubbooks(companyViewCompanyNanoId)
    : TeamPath.currentCompanySubbooks();

  const autoCheckInRulesHref = companyViewCompanyNanoId
    ? TeamPath.companyAutoCheckInRules(companyViewCompanyNanoId)
    : TeamPath.currentCompanyAutoCheckInRules();

  const scTemplatesHref = companyViewCompanyNanoId
    ? TeamPath.companySmartContractTemplates(companyViewCompanyNanoId)
    : TeamPath.currentCompanySmartContractTemplates();

  const eventDispatcherHref = companyViewCompanyNanoId
    ? TeamPath.companyEventDispatcher(companyViewCompanyNanoId)
    : TeamPath.currentCompanyEventDispatcher();

  const loaded = team && (teamFetched || teamIsPlaceholderData);

  const isCurrentUserOwner = currentUser.get('id') === team?.ownerId;

  return (
    <SecondaryMenuWrapper team={team} finTeam={finTeam} teamLoaded={loaded}>
      <CheckPermissions
        actions={compact([
          isSelf
            ? TeamsPermissions.READ_SELF_COMPANY_MENU_INVITE_NEW_USERS_MODAL_BUTTON
            : TeamsPermissions.READ_OTHER_COMPANY_MENU_INVITE_NEW_USERS_MODAL_BUTTON,
          isCurrentUserOwner
            ? TeamsPermissions.READ_COMPANY_MENU_INVITE_NEW_USERS_MODAL_BUTTON_IF_OWNER
            : null
        ])}
      >
        <MenuInviteNewUsersToCompanyModalButton
          companyUuid={team?.uuid}
          cacheKeys={[
            TeamsUserCache.teamTeamsUsersCacheKey(team?.nanoId),
            TeamInvitationCache.teamTeamInvitations(team?.nanoId)
          ]}
        />
      </CheckPermissions>

      <div className="p-4">
        <LoadingSkeleton loaded={loaded}>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_DASHBOARD_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_DASHBOARD_TAB
            }
          >
            {isWorker && (
              <MenuPlainLink
                active={active === TeamTabs.DASHBOARD}
                href={dashboardHref}
                i18nText={teamsKeys.dashboard}
                icon={IconsEnum.TEMPLATE_SOLID}
              />
            )}
          </CheckPermissions>

          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_PROFILE_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_PROFILE_TAB
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.PROFILE}
              href={profileHref}
              i18nText={teamsKeys.profile}
              icon={IconsEnum.USER_CIRCLE_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_MEMBERS_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_MEMBERS_TAB
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.MEMBERS}
              href={usersHref}
              i18nText={teamsKeys.companyUsers}
              icon={IconsEnum.USER_GROUP_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_ARTISTS_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_ARTISTS_TAB
            }
          >
            {!isWorker && (
              <MenuPlainLink
                active={active === TeamTabs.ARTISTS}
                href={performersHref}
                i18nText={teamsKeys.performersTeam}
                icon={IconsEnum.USERS_SOLID}
              />
            )}
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_ITEM_TYPES_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_ITEM_TYPES_TAB
            }
          >
            {!isWorker && (
              <MenuPlainLink
                active={active === TeamTabs.ITEM_TYPES}
                href={itemTypesHref}
                i18nText={teamsKeys.items}
                icon={IconsEnum.COLLECTION_SOLID}
              />
            )}
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_SUBBOOKS_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_SUBBOOKS_TAB
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.SUBBOOKS}
              href={subbooksHref}
              i18nText={teamsKeys.subbooks}
              icon={IconsEnum.TABLE_SOLID}
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_AUTO_CHECK_IN_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_AUTO_CHECK_IN_TAB
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.AUTO_CHECK_IN}
              href={autoCheckInRulesHref}
              i18nText={teamsKeys.autoCheckIn}
              icon={IconsEnum.CHART_PIE}
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_SMART_CONTRACT_TEMPLATES_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_SMART_CONTRACT_TEMPLATES_TAB
            }
          >
            {!isWorker && (
              <MenuPlainLink
                active={active === TeamTabs.SC_TEMPLATE}
                href={scTemplatesHref}
                i18nText={teamsKeys.scTemplates}
                icon={IconsEnum.CHART_PIE}
              />
            )}
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_EVENT_DISPATCHER_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_EVENT_DISPATCHER_TAB
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.EVENT_DISPATCHER}
              href={eventDispatcherHref}
              i18nText={teamsKeys.eventDispatcher}
              icon={IconsEnum.CHART_PIE}
            />
          </CheckPermissions>
          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_REVENUE_SHARE_ITEMS_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_REVENUE_SHARE_ITEMS_TAB
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.REVENUE_SHARE}
              href={incomingRevenueShareHref}
              i18nText={teamsKeys.revenueShare}
              icon={IconsEnum.CHART_PIE}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              isSelf
                ? (!isWorker &&
                    TeamsPermissions.READ_SELF_CLIENT_COMPANY_FILES_TAB) ||
                  (isWorker && TeamsPermissions.READ_SELF_COMPANY_FILES_TAB)
                : (!isWorker &&
                    TeamsPermissions.READ_OTHER_CLIENT_COMPANY_FILES_TAB) ||
                  (isWorker && TeamsPermissions.READ_OTHER_COMPANY_FILES_TAB)
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.FILES}
              href={filesShareHref}
              i18nText={teamsKeys.files}
              icon={IconsEnum.FOLDER_SOLID}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              isSelf
                ? (!isWorker &&
                    TeamsPermissions.READ_SELF_CLIENT_COMPANY_RECORDS_TAB) ||
                  (isWorker && TeamsPermissions.READ_SELF_COMPANY_RECORDS_TAB)
                : (!isWorker &&
                    TeamsPermissions.READ_OTHER_CLIENT_COMPANY_RECORDS_TAB) ||
                  (isWorker && TeamsPermissions.READ_OTHER_COMPANY_RECORDS_TAB)
            }
          >
            <MenuPlainLink
              active={active === TeamTabs.RECORDS}
              href={recordsHref}
              i18nText={teamsKeys.records}
              icon={IconsEnum.BOOK_OPEN_SOLID}
            />
          </CheckPermissions>

          <CheckPermissions
            action={
              isSelf
                ? TeamsPermissions.READ_SELF_COMPANY_REPORTS_TAB
                : TeamsPermissions.READ_OTHER_COMPANY_REPORTS_TAB
            }
          >
            {isWorker && (
              <MenuPlainLink
                active={active === TeamTabs.REPORTS}
                href={reportsHref}
                i18nText={teamsKeys.reports}
                icon={IconsEnum.CHART_BAR_SQUARE_SOLID}
              />
            )}
          </CheckPermissions>
        </LoadingSkeleton>
      </div>
    </SecondaryMenuWrapper>
  );
}

export default TeamProfileSecondaryMenu;
